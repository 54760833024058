import React, { useEffect } from 'react'
import { __ } from '../../../../utils/i18n'
import './Blog.style.scss'
import { Link } from "gatsby"
import moment from 'moment'

export const renderBlog = (el, bclass = '') => {
  const { field_image_string, field_intro_string, name, full_slug,
    published_at, first_published_at } = el

    bclass = bclass ? bclass : 'col-md-4'

    console.log(el.first_published_at, '[renderBlog]')

  return (
    <div key={full_slug} className={`${bclass} blog-post`}>
      <Link to={`/${full_slug}`}>
        <div className="inner">
          <div className="img-wrap">
            <img src={field_image_string} alt={name} />
          </div>
          <div className="body-wrap">
            <h4>{name}</h4>
            <p>{field_intro_string}</p>
            <p className="date">{moment(first_published_at).format('DD/MM/YY')}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}


export const renderOurBlog = ({ posts, t }) => {
  let blogPosts = [];
  posts.posts.edges.forEach(el => blogPosts.push({ ...el.node }))

  console.log(posts, 'the posts')

  return (
    <section className="section-blog sec-pad-std bg-white">
      <div className="inner container-sm">
        <header className="text-center">
          <h2 className="mb-1">{__('Read our blog', t)}</h2>
          <p className="subtitle mb-2 mx-auto">{__('Our Blog Subtitle', t)}</p>
        </header>

        <div className="row">
          {blogPosts.map(el => { return renderBlog(el) })}
        </div>

      </div>
    </section>
  )
}